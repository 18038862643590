<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <ListingComponent ref="BroadcastList"
                      class="bg-white"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <b-modal v-model="isDeleteModal"
             :no-close-on-backdrop="true"
             centered title="Delete modal"
             @cancel="closeDeleteModal"
             @ok="removeItem">
      <form>
        <h3 class="text-black-50">Do you really want to delete</h3>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import ListingComponent from '@/components/listing/ListingComponent'

  export default {
    name: "BroadcastListPage",
    data () {
      return {
        id: null,
        isDeleteModal: false,
        columnConfig: [
          {
            columnCode: 'action',
            columnName: 'Action',
            value: (item) => {
              return (
                  <b-dropdown variant="link" class="listing-action">
                  <b-dropdown-item href={'#/broadcast/edit/' + item.id}>Edit</b-dropdown-item>
                  <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
            }
          },
          {
            columnCode: 'IMAGE',
            columnName: 'Image',
            customizeColumn: true,
            value: item => {
              if (!item.image) return ''
              return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={item.image.imageURL}/>
            }
          },
          {
            columnCode: 'TITLE',
            columnName: 'Title',
            align: 'left',
            value: item => {
              return item.title
            }
          },
          {
            columnCode: 'CONTENT',
            columnName: 'Content',
            align: 'left',
            value: item => {
              return item.content
            }
          }
        ]
      }
    },
    methods: {
      requestProvider (payload) {
        return this.axios.get('public/broadcast/list', payload)
      },
      addNewButton () {
        return (
            <router-link class="btn btn-success mr-3" to="/broadcast/add"><i class="fas fa-plus-circle"></i></router-link>


      )
      },
      closeDeleteModal () {
        this.id = null
        this.$nextTick(() => {
          this.isDeleteModal = false
        })
      },
      showDeleteModal (item) {
        if (!item.id) {
          this.$toastr.error('Item not found')
        }
        this.id = item.id
        this.isDeleteModal = true
      },
      removeItem () {
        if (!this.id) {
          return
        }
        this.axios.delete('broadcast/item/', {params: {id: this.id}})
            .then((result) => {
              this.$refs.BroadcastList.doRequest()
              if (result.data && result.data.status === 'success') {
                this.$toastr.success('successfully deleted')
              }
              this.isDeleteModal = false
            })
      }
    },
    components: {
      ListingComponent
    }
  }
</script>

<style scoped>

</style>